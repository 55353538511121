import NextImage from "next/image";
import SharedServiceInstance from "@/services/shared-service";
import Link from "next/link";
import { ScrollShadow } from "@nextui-org/react";

export default function Banner({banners}) {
    return (
        banners && (
            <>
                <ScrollShadow hideScrollBar={true} className="w-full h-[38rem] md:h-auto">
                    <div className="sm:grid sm:grid-cols-4 gap-10 mb-10 items-center">
                        {banners.map((banner, index) => {
                            if (index >= 0 && index < 4) {
                                return (
                                    <div
                                        key={index}
                                        className="relative w-full max-w-[500px] mx-auto mb-10 sm:mb-0"
                                    >
                                        <Link href={banner.link}>
                                            <NextImage
                                                alt={banner.name}
                                                width={500}
                                                height={500}
                                                priority
                                                sizes="(max-width: 768px) 100vw,
                                (max-width: 1200px) 50vw,
                                33vw"
                                                className="rounded-[5px] !w-full !relative object-contain !h-[unset]"
                                                src={SharedServiceInstance.getOptimizeImage(
                                                    banner.photo,
                                                    "top-slider"
                                                )}
                                            />
                                            <div className="bg-background/80 border-secondary border-2 text-center w-full absolute z-50 bottom-4">
                                                <span className="text-2xl font-bold text-primary">
                                                    {banner.name.toUpperCase()}
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <div className="sm:grid sm:grid-cols-4 gap-10 items-center">
                        {banners.map((banner, index) => {
                            if (index >= 4 && index < 8) {
                                return (
                                    <div
                                        key={index}
                                        className="relative w-full max-w-[500px] mx-auto mb-10 sm:mb-0"
                                    >
                                        <Link href={banner.link}>
                                            <NextImage
                                                alt={banner.name}
                                                width={500}
                                                height={500}
                                                priority
                                                sizes="(max-width: 768px) 100vw,
                                (max-width: 1200px) 50vw,
                                33vw"
                                                className="rounded-[5px] !w-full !relative object-contain !h-[unset]"
                                                src={SharedServiceInstance.getOptimizeImage(
                                                    banner.photo,
                                                    "top-slider"
                                                )}
                                            />
                                            <div className="bg-background/80 border-secondary border-2 text-center w-full absolute z-50 bottom-4">
                                                <span className="text-2xl font-bold text-primary">
                                                    {banner.name.toUpperCase()}
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </ScrollShadow>
            </>
        )
    );
}